import "./movies-page.css";

// fetch(
//   `https://api.themoviedb.org/3/search/movie?api_key=${process.env.TMDB_API}&query=${query}`
// );
//search a show or movie and then get the id and search for the details
export const MoviesPage = () => {
  return (
    <>
      <h1>Movies Page</h1>
    </>
  );
};
