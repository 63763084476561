export const bookData = [
  {
    title: "A Queen in Hiding",
    author: "Sarah Kozloff",
    genre: "Fantasy",
    review: 2.5,
    authorRace: "White",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "Normal People",
    author: "Sally Rooney",
    genre: "Lit",
    review: 3.5,
    authorRace: "White",
    authorGender: "Woman",
    authorNationality: "Irish",
  },
  {
    title: "A Game of Thrones",
    author: "George R.R. Martin",
    genre: "Fantasy",
    review: 4,
    authorRace: "White",
    authorGender: "Man",
    authorNationality: "American",
  },
  {
    title: "Such a Fun Age",
    author: "Kiley Reid",
    genre: "Lit",
    review: 4,
    authorRace: "Black",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "People We Meet on Vacation",
    author: "Emily Henry",
    genre: "Lit/Romance",
    review: 4.5,
    authorRace: "White",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "Book Lovers",
    author: "Emily Henry",
    genre: "Lit/Romance",
    review: 4,
    authorRace: "White",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "Beach Read",
    author: "Emily Henry",
    genre: "Lit/Romance",
    review: 4.5,
    authorRace: "White",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "The Proposal",
    author: "Jasmine Guillory",
    genre: "Romance",
    review: 3,
    authorRace: "Black",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "The Wedding Date",
    author: "Jasmine Guillory",
    genre: "Romance",
    review: 4,
    authorRace: "Black",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "The Wedding Party",
    author: "Jasmine Guillory",
    genre: "Romance",
    review: 3.5,
    authorRace: "Black",
    authorGender: "Woman",
    authorNationality: "American",
  },
  {
    title: "This is How You Lose the Time War",
    author: "Amal El-Mohtar & Max Gladstone",
    genre: "SciFi",
    review: 4,
    authorRace: "?/White",
    authorGender: "Woman/Man",
    authorNationality: "Canadian/American",
  },
  {
    title: "The Viscount Who Loved Me",
    author: "Julia Quinn",
    genre: "Romance",
    review: 3.5,
    authorRace: "White",
    authorGender: "Woman",
    authorNationality: "American",
  },
];
